
.btn-floating {
  border-radius: 50% !important;
  position: fixed;
  padding: 1rem !important;
  line-height: 1.25em !important;
  z-index: 1010;
  top: 100%;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
  @at-root html[dir=rtl] & {
    right: 100%;
    transform: translate(calc(100% + 5vw), calc(-100% - 5vh));
  }

  @at-root html:not([dir=rtl]) & {
    left: 100%;
    transform: translate(calc(-100% + 5vw), calc(-100% - 5vh));
  }

  i {
    color: inherit;
  }
}
