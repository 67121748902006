/*rtl:begin:ignore*/
@import "scss/variables";
@import "scss/radio_slidebox";
@import "scss/floating-button";
@import "../node_modules/@monabbous/slidebox/scss/slidebox";

/*rtl:end:ignore*/
@import "../node_modules/bootstrap/scss/bootstrap";

/*rtl:begin:ignore*/
.card, .page-link {
  @extend .shadow;
  @extend .border-2;
  @extend .border-primary;
}

.form-control {
  @extend .border-primary;
}

.clickable {
  cursor: pointer;
}

.route-container {
  @extend .container-fluid;
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-center;
  min-height: 50vh;
  .route-title {
    @extend .container;
  }
}


input[type=radio].slidebox {
  @extend .slidebox;
}

.form-control[disabled] {
  @extend .form-control, :disabled;
}

/*rtl:end:ignore*/
