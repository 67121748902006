.stat {
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 150px;
  max-height: 15vh;
  height: 7.5vw;
}

.graph {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 400px;
  max-height: 50vh;
  height: 14vw;
}
